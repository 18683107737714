<style lang="less" scoped>

</style>
<template>
  <div>模拟试卷</div>
</template>
<script>
export default {
  
}
</script>